import { graphql } from "gatsby"
import React from "react"
import Layout from "../../layout/Layout/Layout"
import Content from "./Content"
import "./Post.scss"
import { PostProps } from "./types"

const Post: React.FC<PostProps> = ({ data }) => (
  <Layout>
    <div
      style={{
        backgroundColor: `#eee`,
        display: `flex`,
        padding: `32px 0px`,
      }}
    >
      <Content data={data} />
    </div>
  </Layout>
)

export default Post

export const post = graphql`
  query Post($slug: String) {
    sanityPost(slug: { current: { eq: $slug } }) {
      ...PostDetails
    }
    allSanityPost(
      filter: { categories: { elemMatch: { title: { ne: "tech" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
          publishedAt(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`
